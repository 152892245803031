<template>
  <div>
    <div class="mb-2">
      <b-modal id="partial-product-inbound-view-modal" title="Product Inbound Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm table-bordered text-center">
            <thead>
            <tr>
              <th>SL</th>
              <th>Inbound Qty</th>
              <th>Inbound Date</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product_inbound, index) in product_inbounds" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ product_inbound.quantity }}</td>
              <td>{{ customDate(product_inbound.created_at) }}</td>
              <td>{{ product_inbound.comment }}</td>
            </tr>
            </tbody>
          </table>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="danger" class="text-center" @click="$bvModal.hide('partial-product-inbound-view-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import moment from 'moment'

export default {
  name: 'productInboundPartialView',
  props: ['product_inbounds'],
  data() {
    return {}
  },
  mounted() { },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
  },
}
</script>

<style>

</style>
